<template>
  <v-container v-if="$store.state.loader == false" fluid class="pa-0">
    <TabView
      :tabData="tabData"
    />
  </v-container>
</template>

<script>
import TabView from "./../../components/PassiveDataList"
import { DataService } from "../../services/DataService";

const dataService = new DataService();

export default {
  components: {
    TabView
  },
  data() {
    return {
      tabData: [],
    }
  },
  created() {
    this.$store.state.loader = true
  },
  mounted() {
    dataService.passiveDataProcessor(this.$store.state.token, { 
      code: this.$route.query.code,
      processorType: 'getUsersFacebookPageLikes'
    }).then(data => {
      this.tabData = data
      this.$store.state.loader = false
    })
  }
}
</script>